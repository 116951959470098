<template>
<div>
  <unsupported
  :showModal="show.modal"
  :message="supportMessage"
  @closeUnsupported="closeUnsupported()"
  />    
  <div id="welcome-screen">
    <div id="welcome-screen-container">
      <div id="welcome-msg" class="general-modal">
        <h1>{{$t('welcome.heading')}}</h1>
        <br/>
        <p>{{$t('welcome.paragraph1')}}</p>
        <p>{{$t('welcome.paragraph2')}}</p>
        <div v-if="error" class="error-msg">{{error}}</div>
        <div v-else><br/><br/></div>
            <b-row align-h="start">
                <b-col cols="12" md="12">   
                    <label for="email" class="">Please provide your email address to get started.</label>
                    <input
                    v-model.trim="betasignupform.email"
                    maxlength=320
                    id="email"
                    name="email"
                    type="text"
                    class="form-control"
                    required
                    tabindex="1"
                    @click="trackInput('email')"
                    />
                </b-col>
                <b-col cols="12" md="12" style="display:none;">   
                    <label for="zipcode" class="">Zip Code</label>
                    <input
                    v-model.trim="betasignupform.zipcode"
                    maxlength=320
                    id="zipcode"
                    name="zipcode"
                    type="text"
                    class="form-control"
                    required
                    tabindex="2"
                    />
                </b-col>                                 
            </b-row>
        <br/><br/>
        <div class="">
            <div>        
              <b-button pill variant="primary" @click="checkForm()" v-if="saving == false">
                <div class="signup-icon ps-icons"></div>
                {{$t('global.signup')}}
              </b-button>
              <b-button pill variant="secondary" v-else>
                  <div class="loading-icon ps-icons"></div>
                  {{$t('global.saving')}}
              </b-button>                
            </div>
            <br/><br/>
            <div>     
              <b-button pill variant="light" @click="login()">
                <div class="login-icon-purple ps-icons"></div>
                {{$t('global.login')}}
              </b-button>
            </div>
        </div>  
      </div>  
    </div>     
  </div>       
</div>  
</template>
<script>
import unsupported from '@/components/modals/devicesupport.vue';
export default {
  components: { unsupported },
  name: "Welcome",
  mounted (){
    this.tracking({
      type: 'page',
      title: '/welcome',
    });
    this.checkSupport();
  },
  data() {
    return {
      betasignupform: {
          firstname: '',
          lastname: '',
          email: '',
          zipcode: null         
      },
      error: null,
      type: null,
      saving: false,
      showInviteCode: true,
              
      show: {
        modal: false
      },
      supportMessage: "device"
    }
  },
  methods: {
    checkForm() {
        this.error = null;
        this.tracking({
            type: 'event',
            category: 'betasignup-flow',
            action: 'betasignup:complete',
        });  
        if(!this.validEmail(this.betasignupform.email)){
            this.error = this.$t('errors.validemail');
            return;
        } 
        this.saving = true;
        this.sendit();
    },
    async sendit() {
        this.$store.dispatch('betaSignUp', {
            email: this.betasignupform.email,
            firstname: this.betasignupform.firstname,
            lastname: this.betasignupform.lastname,
            zipcode:  this.betasignupform.zipcode,
        })
        .then((results) => {
            if(results.data == 'Already in database.') {
                // already in database
                this.saving = false;
                this.error = 'This email address has already signed up.';
            } else {
                console.log(JSON.stringify(results.data));
                if(results.data.invitecode) {
                    let codeId = results.data.invitecode;
                    this.loadBetaData(codeId);
                } else {
                    this.$router.push('/signup/waitlist');
                }
            }
        })
    },
    async loadBetaData(codeId) {
    try {
      //! fix this logic
      await this.$store.dispatch('signUpCode', codeId)                    
      .then(() => {
        this.$router.push('/signup/confirmed');          
      });
    } catch(error){
        this.message = error;
        this.$store.commit('setSignUp', null);       
    }
    },        
    trackInput(label){
        this.tracking({
            type: 'event',
            category: 'betasignup-flow',
            action: 'betasignup:forminput',
            label: label
        });     
    },      
    login(){
      this.$router.push('/login');
      this.tracking({
          type: 'event',
          category: 'register-flow',
          action: 'welcome:login',
      }); 
    },
    betaSignup(){
      /*this.$router.push('/signup/beta');
      this.tracking({
          type: 'event',
          category: 'betasignup-flow',
          action: 'welcome:signup',
      }); */     
    },
    closeUnsupported() {
      this.show.modal = false;
    },
    openUnsupported() {
      this.show.modal = true;
    },
    checkSupport(){
      if(!this.deviceSupport()) {
        this.show.modal = true;
        this.supportMessage = "device";
      } else if(this.isPhone()) {
        this.show.modal = true;
        this.supportMessage = "nomobile";        
      } else if(!this.isLandscape()) {
        this.show.modal = true;
        this.supportMessage = "direction";        
      } else if(!this.noWebcam()) {
        this.show.modal = true;
        this.supportMessage = "webcam";              
      }
    }        
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';
#welcome-screen{
  position: relative;
  width: 100%;
  max-height: 768px;
  min-height: 500px;
}
#welcome-screen-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
#welcome-msg {
  text-align: left;
  max-width: 400px;
  min-width: 320px;
  max-height: 540px;
  padding: 60px 60px;

}
</style>