<template>
<div id="agecheck-modal-container">
  <div id="key-alien">
    <img src="/img/elements/welcome/alien_with_key.svg" width="304" height="348" :alt="this.$t('agechecker.agecheckaliensimg')" :title="this.$t('agechecker.agecheckaliensimg')"/>
  </div>
  <div id="agecheck-modal">
    <div v-if="showAgeChecker == true">
      <h2>{{$t('agechecker.heading')}}</h2>
      <br/><br/>
      <!--p>{{$t('agechecker.subheading')}}</p-->
        <input
        v-model.trim="ageform.firstnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="firstnumber" 
        name="firstnumber"
        required
        autofocus
        tabindex="1"    
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        />
        <input
        v-model.trim="ageform.secondnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="secondnumber" 
        name="secondnumber"
        required
        
        tabindex="2"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        />
        <input
        v-model.trim="ageform.thirdnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="thirdnumber" 
        name="thirdnumber"
        required
        
        tabindex="3"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        /> 
        <input
        v-model.trim="ageform.forthnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="forthnumber" 
        name="forthnumber"
        required
        
        tabindex="4"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        />  
        <NumPad
        @setNum="numPad($event)"
        @clearNum="clearDigit"
        />                     
      <b-button pill variant="primary" @click="formCheck()">
        <div class="confirm-icon ps-icons"></div>
        {{$t('global.confirm')}}
      </b-button>
    
    </div>
    <div v-if="tooYoung == true">
      <img src="/img/elements/welcome/agecheck_aliens.svg" width="236" height="178"/>
      <h2>{{$t('agechecker.tooyoung')}}</h2>
      <br/><br/>  
      <br/><br/>
      <br/><br/>
      <b-button pill variant="primary" @click="resetForm()">
        <div class="continue-icon ps-icons"></div>
        {{$t('global.continue')}}
      </b-button>

    </div>
  </div>
</div>  
</template>

<script>
import NumPad from '@/components/numpad.vue'
export default {
  name: "agecheck",
  components: {
    NumPad
  },    
  data() {
    return {
      ageform: {
        firstnumber: null,
        secondnumber: null,
        thirdnumber: null,
        forthnumber: null
      },
      error: null,
      birthyear: null,
      showAgeChecker: true,
      tooYoung: false
    }
  },
  methods: {
      formCheck(){
        this.tracking({
          type: 'event',
          category: 'register-flow',
          action: 'signup:confirmyear',
        });         
        this.birthyear = this.ageform.firstnumber.toString() + this.ageform.secondnumber.toString() + this.ageform.thirdnumber.toString() + this.ageform.forthnumber.toString();
        if(this.checkBirthyear(this.birthyear)){
          this.$router.push('/signup')
        } else {
          this.showAgeChecker = false;
          this.tooYoung = true;
          this.tracking({
            type: 'page',
            title: '/welcome/child-welcome',
          });  
        }
      },
      checkBirthyear(year){
          //ensures they are over 18 and under 150 (this keeps random numbers from working)
          let currentYear = new Date().getFullYear();
          let age = currentYear - parseInt(year, 10);
          if( age > 18 && age < 150) { 
            return true;
          } else {
             return false;           
          }
      },
      resetForm() {
        this.birthyear = null;
        this.ageform.firstnumber = null;
        this.ageform.secondnumber = null;
        this.ageform.thirdnumber = null;
        this.ageform.forthnumber = null;
        this.showAgeChecker = true;
        this.tooYoung = false; 
          this.tracking({
            type: 'event',
            category: 'register-flow',
            action: 'signup:child:continue',
          });                
      },
      clearDigit(){
          if(this.ageform.forthnumber != null) {
              this.ageform.forthnumber = null;
          } else if (this.ageform.thirdnumber != null) {
              this.ageform.thirdnumber = null;
          } else if (this.ageform.secondnumber != null) {
              this.ageform.secondnumber = null;
          } else if (this.ageform.firstnumber != null) {
              this.ageform.firstnumber = null;
          }
      },
      numPad(n) {
          if(this.ageform.firstnumber == null) {
              this.ageform.firstnumber = n;
          } else if (this.ageform.secondnumber == null) {
              this.ageform.secondnumber = n;
          } else if (this.ageform.thirdnumber == null) {
              this.ageform.thirdnumber = n;
          } else if (this.ageform.forthnumber == null) {
              this.ageform.forthnumber = n;
          }
      }      
  },
  mounted(){
    this.tracking({
      type: 'page',
      title: '/welcome/agechecker',
    });  
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#agecheck-modal-container {
  position: relative;
  width: 100%;
  height: 100%;
  #lock {
    position:absolute;
    top: 224px;
    left: 161px;
  }
  #key-alien {
    position:absolute;
    top: 40px;
    right: 0px;    
  }
}
#agecheck-modal {
  position: relative;
  margin: 0 auto;
  min-width: 320px;
  max-width: 390px;

  top: 50%;
  transform: translateY(-50%);  
  text-align: center;
  padding: 30px 55px;
  /* Model purple */
  background: #E9E7FF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 30px;

}
</style>