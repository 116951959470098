<template>
  <div id="error-modal-container">
    <div id="error-content">
        <div id="error-msg">
          <h1>{{$t('errors.maintenance')}}</h1>
        </div>       
      <div id="alien">
        <img src="/img/elements/error/offline_alien.svg" width="657" height="352"/>
      </div>
      <div id="cones">
        <img src="/img/elements/error/road_cones.svg" width="374" height="218"/>
      </div>
    </div> 
  </div>  
</template>

<script>
export default {
  name: "Offline",
  mounted (){
    this.tracking({
      type: 'page',
      title: '/maintenance',
    });
  },        
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#error-modal-container {
  position: absolute;
  top: 0px;
  margin: 0 auto;
  width: 1024px;
  height: 768px;
  #error-msg {
    margin: 100px auto 30px;
    width: 300px;
    
    text-align: center;
      h1 {
        color: $ps-white;
      }    
  }
  #alien {
    position:absolute;
    top: 80px;
    right: 8%;
  }
  #cones {
    position:absolute;
    top: 500px;
    left: 150px;    
  }
}
</style>